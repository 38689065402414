<template>
    <div>
        <s-crud
            title="Tipo de Congelamiento"
            height="auto"
			:config="config"
			:filter="filter"
			add
			@save="save($event)"
			edit
			remove
			search-input
        >
            <template v-slot="props">
                <v-container>
                    <v-row>
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-select-definition
                                label="Tipo de Congelamiento"
                                :def="1439"
                                v-model="props.item.TypeFreezing"
                               
                              
                               >
                            </s-select-definition>
                        </v-col> 
                        <!-- <v-col cols="3" lg="3" md="6" sm="12">
                            <s-text
                                disabled
                                label="Descripcion"
                                v-model="PdcDescription"
                                ref="txtPdcDescription">
                            </s-text>
                        </v-col>     -->

                        <v-col cols="3" lg="3" md="6" sm="12"> 
                            <s-select-definition 
                                :def=1173 label="Cultivo" 
                                v-model=props.item.typeCultive>
                            </s-select-definition>  
                        </v-col>
                                                
                        <v-col cols="3" lg="3" md="6" sm="12"> 
                            <s-select-definition 
                                :def=1154 label="Estado" 
                                v-model=props.item.PdcState>
                            </s-select-definition>  
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:PdcState="{row}">
                <v-icon :color="row.PdcState == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
            </template>
        </s-crud>
    </div>
</template>

<script>
import _sProductionCategory from '@/services/FrozenProduction/ProductionCategory.js';
export default{
    data(){
        return {
            config:{
                model:{
                    PdcID:"ID",
                    PdcState: "PdcState",
                },
                service: _sProductionCategory,
                headers:
                [
                    {text:"ID", value:"PdcID"},
                    {text:"Tipo de Congelamiento", value:"TypeFreezingName"},
                    {text:"Cultivo", value:"typeCultiveN"},
                    {text:"Estado", value:"PdcState", align:"center", sorteable:true},
                    
                ]
            },
            filter:{},            
        }
    },
    created(){
            this.initialize()
        },
    methods:{
            save(item){
                if(item.TypeFreezing == undefined)
				{
					this.$fun.alert("Ingrese el nombre", "warning");
					this.$refs.txtTypeFreezing.focus();
					return;
				}
                
                item.save();
            },
            
            initialize()
            {
                
            }
        }
}
</script>